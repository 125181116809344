import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { withTrans } from "../i18n/withTrans"
import i18next from "../i18n/config"
import { StaticImage } from "gatsby-plugin-image"
import Text from "../components/text"

const tr = (de,en)=>(i18next.language==="de"?de:en);

const InfoPage = ({t, ...props}) => (
  <div style={{background: "#cccccc", marginTop:"-10px", overflowX: "hidden"}}>
  <Layout>
    <Seo title="Info"/>
      <StaticImage
          src="../images/logo_alt.png"
          style={{width:"100px", margin: "0 auto", marginBottom: "5px", marginTop: "100px", marginLeft:"calc(50vw - 50px)"}}
          placeholder="tracedSVG"
          alt="logo"/>
      <main id="maincontent">
      <div style={{paddingTop: "100px", paddingBottom: "100px", paddingLeft:"15vw", paddingRight: "15vw", margin:"10px"}}>
          <p className="h1GM">
            <Text tildeStyle={{fontFamily:"BlueMono"}} text={`
              Mit der Ausstellung ~~HfG Ulm: Ausstellungsfieber~~ widmet sich das HfG-Archiv Ulm erstmals der Ausstellungstätigkeit der Hochschule für Gestaltung Ulm (HfG), die wesentlich zu ihrer weltweiten Wahrnehmung beitrug.
              Zweifellos eine der einflussreichsten Gestaltungshochschulen des 20. Jahrhunderts, war die HfG Wegbereiterin für das heutige Verständnis von Design als interdisziplinäre Praxis. Mittels bislang unveröffentlichten Materials wie Architekturmodellen, Fachzeitschriften und historischen Fotografien veranschaulicht die Ausstellung ~~HfG Ulm: Ausstellungsfieber~~, wie die Hochschule ihre Positionen und Designkonzepte in die Welt trug.


              In den 1950er und 60er Jahren erfreuten sich Ausstellungen derartiger Beliebtheit, dass Fachleute von einem „Ausstellungsfieber“ sprachen. Design war Thema auf zahlreichen Ausstellungen und Messen, die HfG nahm sich von diesen Entwicklungen nicht aus: Während ihres 15-jährigen Bestehens erarbeitete die Hochschule mehrere Ausstellungen, die dem Bedürfnis folgten, den eigenen Standpunkt sichtbar zu machen und ihre entwickelten Lehrmethoden zu verbreiten. Neben diesen Selbstdarstellungen entwarfen die Hochschulangehörigen Systeme für externe Auftraggeber wie Braun und BASF sowie einen Beitrag für den Deutschen Pavillon auf der Weltausstellung 1967. Ausstellungen dienten der HfG immer als Experimentierfeld für gestalterische Innovationen. Die dynamische Atmosphäre jener Jahrzehnte wird für die Besucher*innen der Ausstellung ~~HfG Ulm: Ausstellungsfieber~~ durch verschiedene Medien erlebbar. Dabei nimmt sie sowohl die Relevanz der Ulmer Hochschule und ihre weltweite Rezeption als auch die Suche nach einer idealen Ausstellungsgestaltung in den Blick.


              Die Präsentation ~~HfG Ulm: Ausstellungsfieber~~ bildet den Abschluss des von der VolkswagenStiftung geförderten vierjährigen Forschungsprojekts ~~Gestaltung ausstellen. Die Sichtbarkeit der HfG Ulm~~, das von der Folkwang Universität der Künste Essen, dem HfG-Archiv Ulm und der Hochschule Pforzheim gemeinsam getragen wird.
              Die Projektwebsite wird von der VolkswagenStiftung im Rahmen des Forschungsprojekts gefördert.


              `}/>
          </p>



        <h2 className="p11B">{tr("Ausstellungsteam:", "Exhibition team:")}</h2>
        <p className="p11GM">
        <Text text={tr(
`
Kuratorisches Team: Viktoria Heinrich M.A. (Leitung), Christopher Haaf M.A., Linus Rapp M.A.
Gestaltung: Studio Erika, Kempten
Projektleitung: Prof. Dr. Thomas Hensel (HS Pforzheim), Dr. Martin Mäntele (HfG-Archiv Ulm), Prof. Dr. Steffen Siegel (Folkwang Universität der Künste, Essen)
Recherche: Katharina Kurz M.A. (2017-2019)
Übersetzung: Peter Blakeney & Christine Schöffler`,

`
Curatorial team: Viktoria Heinrich M.A. (Head), Christopher Haaf M.A., Linus Rapp M.A.
Design: Studio Erika, Kempten
Project management: Prof. Dr. Thomas Hensel (Pforzheim University), Dr. Martin Mäntele (HfG-Archiv Ulm), Prof. Dr. Steffen Siegel (Folkwang University of the Arts, Essen)
Research: Katharina Kurz M.A. (2017-2019)
Translation: Peter Blakeney & Christine Schöffler`)}/>
        </p>

        </div>

        <div style={{display: "flex", alignItems:"center", justifyContent:"center", flexFlow:"row wrap", paddingBottom:"100px", paddingLeft:"15vw", paddingRight:"15vw"}}>
        <StaticImage
            src="../images/logo_hfg_trans.png"
            placeholder="tracedSVG"
            style={{width:"calc(25% - 40px)", minWidth:"200px", margin:"20px"}}
            alt="HfG Logo"/>
        <StaticImage
            src="../images/logo_folkwang.png"
            placeholder="tracedSVG"
            style={{width:"calc(25% - 40px)", minWidth:"200px", margin:"20px"}}
            alt="Folkwang Logo"/>
        <StaticImage
            src="../images/logo_hspf.png"
            placeholder="tracedSVG"
            style={{width:"calc(25% - 40px)", minWidth:"200px", margin:"20px"}}
            alt="HS PF Logo"/>
        <StaticImage
            src="../images/logo_vwstift.png"
            placeholder="tracedSVG"
            style={{width:"calc(25% - 40px)", minWidth:"200px", margin:"20px"}}
            alt="Volkswagen Stiftung Logo"/>
        </div>

        <div style={{paddingTop: "100px", paddingBottom: "20vh", paddingLeft:"15vw", paddingRight: "15vw", background:"rgb(244, 240, 240)", margin:"-10px"}}>

          <h2 className="p11B">HfG-Archiv Ulm / Museum Ulm</h2>
          { i18next.language==="de"?
            <>
              <p className="p11GM">
              <Text text={`
                Das HfG-Archiv bietet Wissenschaftler*innen, Student*innen und jedem interessierten Laien die Möglichkeit, sich mit der Geschichte der Ulmer Hochschule auseinanderzusetzen. Neben der Einsicht in die vielfältigen Archivmaterialien kann die historische Bibliothek der ehemaligen Hochschule sowie eine Sammlung aktueller Bücher und Zeitschriften zur Designgeschichte als Präsenzbibliothek benutzt werden. Weitere zur Archivnutzung finden sich auf `}/>
                <a href="https://hfg-archiv.museumulm.de/archivnutzung/" target="_blank" rel="noopener noreferrer" style={{textDecoration:"underline"}}>
                  {"unserer Webseite."}
                </a>
                <Text text={`
                Mit Hilfe unserer `}/>
                <a href="https://hfg-archiv.museumulm.de/archivnutzung/" target="_blank" rel="noopener noreferrer" style={{textDecoration:"underline"}}>{"digitalen Findbücher"}</a>
                <Text text={` können Sie sich einen Überblick über die Bestände des HfG-Archivs verschaffen.

                HfG-Archiv Ulm
                Am Hochsträß 8 | 89081 Ulm
                Bürozeiten:
                Mo-Fr, 09:00-13:00 Uhr
                Kontakt
                +49 (0) 731 161-4370
                `}/><a href="mailto:hfg-archiv@ulm.de" style={{textDecoration:"underline"}}>{"hfg-archiv@ulm.de"}</a><Text text={`
                `}/><a href="http://www.hfg-archiv.museumulm.de" target="_blank" rel="noopener noreferrer" style={{textDecoration:"underline"}}>{"www.hfg-archiv.museumulm.de"}</a><Text text={`

                  `}/>
              </p>
            </>:<>
              <p className="p11GM">
              <Text text={`
                The HfG-Archiv offers researchers, students and the general public the opportunity to learn about and explore the history of the Ulm School of Design (1953-1968).

                Available are original sources, including files, three dimensional models for product design and two dimensional graphic designs, the former library of the Ulm School of Design as well as current literature about design and design history.

                Further information on archive use can be found on `}/>
                <a href="https://hfg-archiv.museumulm.de/en/archival-research-and-service/" target="_blank" rel="noopener noreferrer" style={{textDecoration:"underline"}}>
                  {"our website."}
                </a>

                <Text text={`
                `}/>
                <a href="https://hfg-archiv.museumulm.de/archivnutzung/" target="_blank" rel="noopener noreferrer" style={{textDecoration:"underline"}}>
                {"Our inventories"}
                </a>
                <Text text={` (in german language) are the key to our collections. In there, you find detailed information in order to get an overview on the material and for the preparation of your research at the archive.

                  File submission and archive research every Wednesday 9 a.m. – 5 p.m.
                  We kindly ask you to make an appointment by telephone at
                  +49 (0) 731 161-4370 or by mail to
                `}/><a href="mailto:hfg-archiv@ulm.de" style={{textDecoration:"underline"}}>{"hfg-archiv@ulm.de"}</a>
                <Text text={`

                  `}/>
              </p>
            </>
          }

      </div>
      </main>
  </Layout>
  </div>
)

export default withTrans(InfoPage)
